<template>
    <div class="selectionNode" v-loading="loading">
        <div class="selectionNodeContent">
            <div class="el_left processNode">
                <h5>流程节点</h5>
                <ul>
                    <template v-for="(item, index) in nodeData">
                        <li :class="{'active': currentNode == index}" :key="index" @click="selectNode(index, item)">
                            <span v-if="item.ApproverType == 2">选择</span>
                            <span v-if="item.ApproverType == 1">查看</span>
                            <span v-if="item.IsHandled" class="isApproved">已办</span>
                            <p>{{ item.BusinessPhaseName }}</p>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="el_left selectionOfficer">
                <template v-if="nodeReturnData.IsCrossHierarchy == false">
                    <div class="choseMembers" v-if="nodeApproverCategory == 2">
                        <div class="contant">
                            <div class="search iconfont iconsousuo">
                                <el-input placeholder="请输入待选人员名称" v-model="queryCriteria" @change="query"></el-input>
                            </div>
                            <div class="clearfix createTeamCon">
                                <div class="role el_left">
                                    <div class="tit">
                                        角色
                                    </div>
                                    <ul>
                                        <template v-for="(item, index) in approverRoleName">
                                            <li :key="index" :class="{roleSelection:index == currentIndex}" @click="obtainPersonnel(index)">
                                                <i class="iconfont iconminsuzhuguanli"></i>
                                                <span>{{ item }}</span>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                                <div class="transContainer el_left">
                                    <div class="trans el_left">
                                        <template v-if="isRadio">
                                            <div class="tit">
                                                待选人员
                                            </div>
                                            <div class="trans-con">
                                                <el-radio-group v-model="radioWaitMembers">
                                                    <template v-for="(item, index) in waitMembersData">
                                                        <el-radio
                                                            :label="item.user_id"
                                                            :key="index"
                                                        >
                                                            {{ item.user_name }}
                                                        </el-radio>
                                                    </template>
                                                </el-radio-group>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="tit">
                                                <el-checkbox v-model="checkWaitAll" @change="handleWaitAllChange">
                                                    待选人员
                                                </el-checkbox>
                                            </div>
                                            <div class="trans-con">
                                                <el-checkbox-group v-model="checkedWaitMembers" @change="handleCheckedWaitChange">
                                                    <template v-for="item in waitMembersData">
                                                        <el-checkbox
                                                            :label="item"
                                                            :key="item.user_id"
                                                        >
                                                            {{ item.user_name }}
                                                        </el-checkbox>
                                                    </template>
                                                </el-checkbox-group>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="el_left trans-arrow">
                                        <div class="buttons">
                                            <span class="button-item" @click="transRight">
                                                <i class="iconfont iconjiantouyou"></i>
                                            </span>
                                            <span class="button-item" @click="transLeft">
                                                <i class="iconfont iconjiantouzuo"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="trans el_left">
                                        <template v-if="isRadio">
                                            <div class="tit">
                                                已选人员
                                            </div>
                                            <div class="trans-con">
                                                <el-radio-group v-model="radioChosedMembers">
                                                    <template v-for="(item, index) in chosedMembersData">
                                                        <el-radio
                                                            :label="item.user_id"
                                                            :key="index"
                                                        >
                                                            {{ item.user_name }}
                                                        </el-radio>
                                                    </template>
                                                </el-radio-group>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="tit">
                                                <el-checkbox v-model="checkChosedAll" @change="handleChosedAllChange">
                                                    已选人员
                                                </el-checkbox>
                                            </div>
                                            <div class="trans-con">
                                                <el-checkbox-group v-model="checkedChosedMembers" @change="handleCheckedChosedChange">
                                                    <template v-for="item in chosedMembersData">
                                                        <el-checkbox
                                                            :label="item"
                                                            :key="item.user_id"
                                                        >
                                                            {{ item.user_name }}
                                                        </el-checkbox>
                                                    </template>
                                                </el-checkbox-group>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fixedPersonnel" v-if="nodeApproverCategory == 1">
                        <template v-for="(item, index) in approverRoleName">
                            <div :key="index">
                                <img src="./../../../assets/images/sale/head_portrait2.png" alt="">
                                <span>{{ item }}</span>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </div>
        <footer>
            <el-button type="primary" @click="selectionNode">
                确 定
            </el-button>
            <el-button @click="handleClose">
                取 消
            </el-button>
        </footer>
    </div>
</template>

<script>

export default {
    // 流程提交选择节点页面
    name: 'selection-node',
    components: {},
    props: {
        businessPhaseId: {
            type: [Array, Object, String ],
            required: true, // 是否必传
        },
        phaseType: {
            type: [Array, Object, String, Number ],
            required: true, // 是否必传
        },
        isFreeSubmission: {
            type: [Boolean],
        },
    },
    data() {
        return {
            // 加载状态
            loading: false,
            // 是否是自由提交
            isFree: false,
            // 环节数据集合
            nodeData: [],
            // 当前选择环节下标
            currentNode: 0,
            // 当前环节审批人类别
            nodeApproverCategory: 0,
            // 当前环节id
            currentNodeId: '',
            // 是否是第一环节
            IsFirstPhase: false,
            // 环节审批人
            HandledUsers: [],
            // 是否允许修改办理人
            isAllowEditApprover: false,
            // 环节审批角色id
            approverRoleId: [],
            // 环节审批角色name
            approverRoleName: [],
            // 选择环节返回数据
            nodeReturnData: {
                IsCrossHierarchy: false,
                NextPhsaeId: '',
                NextApproverIds: '',
                NextApproverNames: '',
            },
            // 是否单选
            isRadio: true,
            // 当前角色下标
            currentIndex: 0,
            // 待选-人员
            waitMembersData: [],
            // 待选-已选人员（多选）
            checkedWaitMembers: [],
            // 待选-已选人员（单选）
            radioWaitMembers: '',
            // 待选-全选 
            checkWaitAll: false,
            // 已选-人员
            chosedMembersData: [],
            // 已选-已选人员（多选）
            checkedChosedMembers: [],
            // 已选-已选人员（单选）
            radioChosedMembers: '',
            // 已选-全选 
            checkChosedAll: false,
            // 查询条件
            queryCriteria: '',
        };
    },
    created() {
        this.isFree = this.isFreeSubmission;
        // if (this.phaseType === 3 || this.phaseType === 4) {
        //     this.isRadio = false;
        // }

        // 初始化页面
        this.init();
    },
    mounted() {},
    watch: {
        isFreeSubmission(newVal) {
            this.isFree = newVal;
            // 初始化页面
            this.init();
        },
    },
    computed: {},
    methods: {
        // 初始化页面
        init() {
            this.loading = true;
            // 获取节点数据
            this.gitNodeData();
            // 初始化第一个角色数据
            this.obtainPersonnel(0);
        },
        // 获取节点数据
        gitNodeData() {
            let getUrl = '';
            if (this.isFree) {
                getUrl = `/interfaceApi/basicplatform/simpleflow/processes/phases_other/${this.businessPhaseId}`;
            } else {
                getUrl = `/interfaceApi/basicplatform/simpleflow/processes/phases/${this.businessPhaseId}`;
            }
            this.$axios
                .get(getUrl)
                .then(res => {
                    const newData = res;
                    newData.forEach(item => {
                        // 审批人id
                        if (item.ApproverId) {
                            item.ApproverId = item.ApproverId.split(',');
                        }
                        // 审批人name
                        if (item.ApproverName) {
                            item.ApproverName = item.ApproverName.split(',');
                        }
                        // 审批角色id
                        if (item.ApproverRoleId) {
                            item.ApproverRoleId = item.ApproverRoleId.split(',');
                        }
                        // 审批角色name
                        if (item.ApproverRoleName) {
                            item.ApproverRoleName = item.ApproverRoleName.split(',');
                        }
                    });

                    // 环节数据
                    this.nodeData = newData;
                    // 当前环节审批人类别
                    this.nodeApproverCategory = this.nodeData[0].ApproverType;
                    // 当前环节id
                    this.currentNodeId = this.nodeData[0].BusinessPhaseId;
                    // 是否允许修改办理人;
                    this.isAllowEditApprover = this.nodeData[0].IsAllowEditApprover;
                    // 是否是第一环节
                    this.IsFirstPhase = this.nodeData[0].IsFirstPhase;
                    // 环节审批人
                    this.HandledUsers = this.nodeData[0].HandledUsers;

                    // 默认返回值
                    this.nodeReturnData.IsCrossHierarchy = this.nodeData[0].IsCrossHierarchy;
                    this.nodeReturnData.NextPhsaeId = this.currentNodeId;
                    this.nodeReturnData.NextApproverIds = this.dataChange(newData[0].ApproverId);
                    this.nodeReturnData.NextApproverNames = this.dataChange(newData[0].ApproverName);

                    if (this.nodeData[0].IsCrossHierarchy !== true) {
                        // 过滤审批人类别数据
                        this.filterData(this.nodeData[0]);
                    }

                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 过滤审批人类别数据
        filterData(data) {
            if (this.nodeApproverCategory === 1) {
                // 默认第一个环节id赋值
                this.approverRoleId = data.ApproverId;
                // 默认第一个环节名称赋值
                this.approverRoleName = data.ApproverName;
            } else if (this.nodeApproverCategory === 2) {
                // 默认第一个环节id赋值
                this.approverRoleId = data.ApproverRoleId;
                // 默认第一个环节名称赋值
                this.approverRoleName = data.ApproverRoleName;
                // 默认拉取第一个角色下人员
                this.obtainPersonnel(0);
            }
        },
        // 选择节点
        selectNode(index, data) {
            this.waitMembersData = [];
            this.chosedMembersData = [];
            this.checkedWaitMembers = [];
            this.currentNode = index;
            // 当前环节审批人类别
            this.nodeApproverCategory = data.ApproverType;
            // 是否是跨级审批
            this.nodeReturnData.IsCrossHierarchy = data.IsCrossHierarchy;
            // 当前环节id
            this.currentNodeId = data.BusinessPhaseId;
            // 是否允许修改办理人;
            this.isAllowEditApprover = data.IsAllowEditApprover;
            // 是否是第一环节
            this.IsFirstPhase = data.IsFirstPhase;
            // 环节审批人
            this.HandledUsers = data.HandledUsers;

            if (data.IsCrossHierarchy !== true) {
                // 过滤审批人类别数据
                this.filterData(data);
            }
        },
        // 数组对象转字符串
        dataChange(data) {
            let arrayData = [];
            if (Array.isArray(data)) {
                arrayData = data;
            } else {
                arrayData.push(data);
            }
            let newData = '';
            arrayData.forEach((item, index) => {
                if (index !== arrayData.length - 1) {
                    newData += item + ',';
                } else {
                    newData += item;
                }
            });
            return newData;
        },
        // 确认选择节点
        selectionNode() {
            if (this.IsFirstPhase) {
                this.nodeReturnData.NextApproverIds = this.HandledUsers[0].ApproverId;
                this.nodeReturnData.NextApproverNames = this.HandledUsers[0].ApproverName;
            } else if (this.nodeApproverCategory === 1 || this.nodeReturnData.IsCrossHierarchy === true) {
                this.nodeReturnData.NextPhsaeId = this.currentNodeId;
                if (this.approverRoleId) {
                    this.nodeReturnData.NextApproverIds = this.dataChange(this.approverRoleId.ApproverId);
                }
                if (this.approverRoleName) {
                    this.nodeReturnData.NextApproverNames = this.dataChange(this.approverRoleName.ApproverName);
                }
            } else if (this.nodeApproverCategory === 2) {
                if (this.chosedMembersData.length > 0) {
                    if (this.isAllowEditApprover) {
                        // 选择环节返回数据
                        this.nodeReturnData.NextPhsaeId = this.currentNodeId;
                        this.nodeReturnData.NextApproverIds = this.dataChange(this.chosedMembersData[0].user_id);
                        this.nodeReturnData.NextApproverNames = this.dataChange(this.chosedMembersData[0].user_name);
                    } else {
                        this.nodeReturnData.NextPhsaeId = this.currentNodeId;
                        this.nodeReturnData.NextApproverIds = null;
                        this.nodeReturnData.NextApproverNames = null;
                        this.nodeReturnData.isAllowEditApprover = false;
                    }
                } else {
                    this.$message.error('请选择人员！');
                }
            }
            this.$emit('selectionNodeCallback', this.nodeReturnData);
        },
        // 取消选择节点
        handleClose() {
            this.$emit('deselected');
        },
        // 获取角色下人员
        obtainPersonnel(index) {
            this.chosedMembersData = [];
            this.checkedWaitMembers = [];
            this.currentIndex = index;
            const roleid = this.approverRoleId[index];
            this.$axios
                .get('/interfaceApi/basicplatform/roleusers/' + roleid)
                .then(res => {
                    this.loading = false;
                    this.waitMembersData = res;
                    this.filteringData(this.waitMembersData);
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 条件查询当前角色下人员
        query() {
            this.loading = true;
            const queryResults = [];
            if (this.queryCriteria !== '') {
                this.waitMembersData.forEach(item => {
                    if (item.user_name.indexOf(this.queryCriteria) !== -1) {
                        queryResults.push(item);
                    }
                });
                this.waitMembersData = queryResults;
                this.filteringData(this.waitMembersData);
            } else {
                this.obtainPersonnel(0);
            }
        },
        // 过滤数据，如果只有一条直接选中
        filteringData(data) {
            this.chosedMembersData = [];
            this.radioWaitMembers = '';
            this.radioChosedMembers = '';
            this.checkedWaitMembers = [];
            this.checkedChosedMembers = [];
            if (data.length === 1) {
                if (this.isRadio) {
                    this.radioWaitMembers = data[0].user_id;
                    this.radioChosedMembers = data[0].user_id;
                } else {
                    this.checkedWaitMembers.push(data[0]);
                    this.checkedChosedMembers.push(data[0]);
                }
                this.chosedMembersData = data;
            }
            this.loading = false;
        },
        /* 待选列表选中事件 */
        handleWaitAllChange() {
            this.checkedWaitMembers = this.checkWaitAll ? this.waitMembersData : [];
        },
        handleCheckedWaitChange(value) {
            const checkedCount = value.length;
            this.checkWaitAll = checkedCount === this.waitMembersData.length;
        },
        /* 已选列表选中事件 */
        handleChosedAllChange() {
            this.checkedChosedMembers = this.checkChosedAll ? this.chosedMembersData : [];
        },
        handleCheckedChosedChange(value) {
            const checkedCount = value.length;
            this.checkChosedAll = checkedCount === this.chosedMembersData.length;
        },
        // 向右选取
        transRight() {
            const _that = this;
            if (this.isRadio) {
                _that.chosedMembersData = [];
                this.waitMembersData.forEach(item => {
                    if (item.user_id === _that.radioWaitMembers) {
                        _that.chosedMembersData.push(item);
                    }
                });
            } else {
                const newArr = [..._that.chosedMembersData, ..._that.checkedWaitMembers];
                _that.chosedMembersData = Array.from(new Set(newArr));
                _that.checkChosedAll = _that.checkedChosedMembers.length === _that.chosedMembersData.length;
            }
        },
        // 向左选取,删除已选中
        transLeft() {
            if (this.isRadio) {
                this.chosedMembersData = [];
            } else {
                this.chosedMembersData = this.chosedMembersData.filter(item => !this.checkedChosedMembers.some(ele => ele.user_id === item.user_id));
                this.checkChosedAll = this.checkedChosedMembers.length === this.chosedMembersData.length;
            }
        },
    },
};
</script>
<style lang="stylus">
.selectionNode
    .el-radio,
    .el-checkbox
        margin-right 0
        width 100%
    header
        padding-right: 0.6rem;
        height: 0.6rem;
        background: #6a7ebe;
        p
            font-size: 0.18rem;
            line-height: 0.6rem;
            text-indent: 0.24rem;
            text-align center
            color: #fff;
    .selectionNodeContent
        height 4.65rem
        overflow-x hidden
        overflow-y auto
        .processNode
            width 2.2rem
            height 100%
            padding 0.2rem
            overflow-x hidden
            overflow-y auto
            border-right 0.04rem solid #F0F0F0
            h5
                font-size 0.16rem
                line-height 0.16rem
                padding-left 0.1rem
                color #333333
                border-left 4px solid #979EA7
            ul
                li
                    height 0.8rem
                    margin-top 0.2rem
                    border 1px solid #979EA7
                    border-left 4px solid #21BDCC
                    position relative
                    padding-left 0.1rem
                    &:before,
                    &:after
                        display none
                    &:before
                        content ''
                        width 0.4rem
                        height 0.4rem
                        background url(./../../../assets/images/sale/pitch.png)
                        background-size 100% 100%
                        position absolute
                        right 0
                        top 0
                    &:after
                        content ''
                        width 0.08rem
                        height 0.17rem
                        background url(./../../../assets/images/sale/arrow.png)
                        background-size 100% 100%
                        position absolute
                        right -0.12rem
                        top 50%
                        margin-top -0.08rem
                    &.active
                        border 4px solid #5588F1
                        border-left 8px solid #5588F1
                        box-shadow 0px 0px 5px rgba(0,0,0,0.35)
                        &:before,
                        &:after
                            display block
                        span
                            background #5588F1
                        p
                            color #5588F1
                            font-weight 600
                    span
                        display inline-block
                        height 0.2rem
                        font-size 0.12rem
                        line-height 0.2rem
                        color #fff
                        background #21BDCC
                        border-radius 0.09rem
                        padding 0 0.1rem
                        margin 0.1rem 0
                        &.isApproved
                            background rgba(22,168,248,.8)
                            margin-left 0.1rem
                    p
                        font-size 0.18rem
                        color #333333
        .selectionOfficer
            width calc(100% - 2.24rem)
            height 100%
            position initial
            box-shadow none
            .choseMembers
                height 100%
                overflow hidden
                .contant
                    height 100%
                    padding 0.2rem
                    .search
                        width 100%
                        height 0.4rem
                        position relative
                        margin-bottom 0.15rem
                        &:before
                            position absolute
                            right 0.1rem
                            font-size 0.2rem
                            top 0
                            color #BBBBBB
                            z-index 2
                            line-height 0.36rem
                    .createTeamCon
                        overflow hidden
                        height 3.72rem
                        .role
                            width 2.12rem
                            height 100%
                            border 1px solid #D7D7D7
                            margin-right 0.37rem
                            .tit
                                height 0.34rem
                                line-height 0.34rem
                                background #ECF1F6
                                margin 0
                                padding-left 0.15rem
                                -webkit-box-sizing border-box
                                box-sizing border-box
                                color #000
                                font-size 0.16rem
                            ul
                                height calc(100% - 0.34rem)
                                overflow-x hidden
                                overflow-y auto
                                li
                                    font-size 0.14rem
                                    line-height 0.34rem
                                    padding-left 0.2rem
                                    border-bottom 1px solid #F0F0F0
                                    i
                                        color #979EA7
                                        margin-right 0.1rem
                                    span
                                        color #333333
                                    &.roleSelection
                                        i,
                                        span
                                            color #5588F1
                                    &:hover
                                        background #FBFBFB
                        .transContainer
                            width calc(100% - 2.5rem)
                            height 100%
                            .trans
                                height 100%
                                width calc((100% - 0.37rem)/2)
                                border 1px solid #D7D7D7
                                .tit
                                    height 0.34rem
                                    line-height 0.34rem
                                    background #ECF1F6
                                    margin 0
                                    padding-left 15px
                                    -webkit-box-sizing border-box
                                    box-sizing border-box
                                    color #000
                                    font-size 0.16rem
                                    .el-checkbox__input
                                        margin-top -2px
                                    .el-checkbox__label
                                        color #000
                                        font-size 0.16rem
                            .trans-arrow
                                width 0.37rem
                                height 100%
                                padding-top 35%
                                .buttons
                                    display inline-block
                                    vertical-align middle
                                    .button-item
                                        width 0.27rem
                                        height 0.27rem
                                        border 1px solid #dee1e7
                                        background #f5f7fa
                                        text-align center
                                        border-radius 50%
                                        display block
                                        margin 0 0.05rem
                                        display inline-block
                                        margin-bottom 0.1rem
                                        cursor pointer
                                        color #cbcfd6
                                        text-align center
                                        i
                                            font-size 0.16rem
                                            line-height 0.27rem
                                        &:nth-of-type(1)
                                            &:hover
                                                background #409eff
                                                border 1px solid #409eff
                                                color #fff
                                        &:nth-of-type(2)
                                            &:hover
                                                background #D40004
                                                border 1px solid #D40004
                                                color #fff
                            .trans-con
                                height calc(100% - 34px)
                                overflow-y auto
                                overflow-x hidden
                                width 100%
                                .el-radio-group
                                .el-checkbox-group
                                    font-size 0.14rem
                                    line-height 0.34rem
                                    padding-left 0.15rem
                                    .el-radio__input
                                        margin-top -2px
                    .transPagination
                        text-align center
                        >div
                            display inline-block
                    .footer
                        display inline-block
                        width 100%
                        text-align center
                        margin-top 0.1rem
                        button
                            padding 7px 9px
            .fixedPersonnel
                width 100%
                height 100%
                text-align center
                padding-top 0.8rem
                >div
                    display inline-block
                    width 2rem
                    height 3rem
                    border-top 0.06rem solid #5588F1
                    box-shadow 0px 0px 8px rgba(85, 136, 241, 0.45)
                    text-align center
                    position relative
                    border-radius 2px
                    margin 0 0.4rem
                    vertical-align top
                    &:after
                        content ''
                        width 0.77rem
                        height 0.3rem
                        background url(./../../../assets/images/sale/select.png)
                        background-size 100% 100%
                        position absolute
                        top -9px
                        left 0
                    img
                        width 1.2rem
                        height 1.2rem
                        margin-top 0.4rem
                        margin-bottom 0.3rem
                        display inline-block
                    span
                        display block
                        width 100%
                        font-size 0.25rem
                        color #333333
                        padding 0 0.3rem
    footer
        text-align center
        height 0.95rem
        border-top 0.04rem solid #F0F0F0
        padding-top 0.26rem
        button
            width 1.6rem
            height 0.4rem
            margin 0 0.3rem
            padding 0
    .selectionOfficer
        background #fff
        position absolute
        bottom 0
        right 0
        z-index 10
        width 7.5rem
        height 5.4rem
        box-shadow -8px 0 16px rgba(0,0,0,0.2)
</style>